<template>
  <section class="cms-customer">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <v-container fluid>
        <Alert
          :alertShow="alertShow"
          :alertText="alertText"
          :alertType="alertType"
          @closeAlert="closeAlert"
        />
        <v-row>
          <v-col class="mt-3" md="6"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><v-btn
                  @click.prevent="showCustomerCreateView"
                  block
                  color="green"
                  large
                  dark
                  >Dodaj firmę <v-icon>mdi-domain-plus</v-icon></v-btn
                ></v-card-text
              ></v-card
            ></v-col
          >
          <v-col class="mt-md-3" md="6"
            ><v-card elevation="2"
              ><v-card-text class="text-h4 text-center text--primary"
                ><v-btn
                  @click.prevent="showClientCreateView"
                  block
                  color="green"
                  large
                  dark
                  >Dodaj klienta
                  <v-icon>mdi-account-plus-outline</v-icon></v-btn
                ></v-card-text
              ></v-card
            ></v-col
          >
        </v-row>
        <v-row v-if="this.customers.length > 0">
          <v-col md="6">
            <CustomersTable
              :customers="customers"
              :selectedCustomerRow="selectedCustomerRow"
              :customerTableLoading="customerTableLoading"
              @showCustomerEditView="showCustomerEditView"
              @toggleCustomerSelection="toggleCustomerSelection"
              @toogleCustomerActive="toogleCustomerActive"
            />
          </v-col>
          <v-col md="6">
            <ClientsTable
              v-if="selectedCustomerRow.active"
              :clients="selectedClients"
              :clientTableLoading="clientTableLoading"
              :apiLink="apiLink"
              @showClientEditView="showClientEditView"
              @toogleClientActive="toogleClientActive"
              @showAlert="showAlert"
            />
            <h3 v-else class="text-h6 text-center">
              Nie można zmieniać klientów w nieaktywnej firmie!
            </h3>
          </v-col>
        </v-row>
        <CustomerEdit
          :customerEditView="customerEditView"
          :customerToEdit="customerToEdit"
          @showAlert="showAlert"
          @closeCustomerEditView="closeCustomerEditView"
          @updateCustomer="updateCustomer"
        />
        <ClientEdit
          :clientEditView="clientEditView"
          :clientToEdit="clientToEdit"
          :customers="customers"
          @showAlert="showAlert"
          @closeClientEditView="closeClientEditView"
          @updateClient="updateClient"
        />
        <CustomerCreate
          :customerCreateView="customerCreateView"
          :apiLink="apiLink"
          @closeCustomerCreateView="closeCustomerCreateView"
          @showAlert="showAlert"
          @getCustomers="getCustomers"
        />
        <ClientCreate
          :clientCreateView="clientCreateView"
          :customers="customers"
          :apiLink="apiLink"
          @closeClientCreateView="closeClientCreateView"
          @showAlert="showAlert"
          @getClients="getClients"
        />
      </v-container>
    </div>
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import CustomerEdit from "@/components/CMS/Customers/CustomerEdit.vue";
import ClientEdit from "@/components/CMS/Customers/ClientEdit.vue";
import CustomersTable from "@/components/CMS/Customers/CustomersTable.vue";
import ClientsTable from "@/components/CMS/Customers/ClientsTable.vue";
import CustomerCreate from "@/components/CMS/Customers/CustomerCreate.vue";
import ClientCreate from "@/components/CMS/Customers/ClientCreate.vue";

export default {
  name: "CMSCustomers",
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Alert,
    CustomerEdit,
    ClientEdit,
    CustomersTable,
    ClientsTable,
    CustomerCreate,
    ClientCreate,
  },
  data() {
    return {
      customerEditView: false,
      clientEditView: false,
      customerCreateView: false,
      clientCreateView: false,
      customers: [],
      selectedCustomerRow: {},
      customerTableLoading: true,
      selectedClientRow: {},
      clientTableLoading: true,
      clients: [],
      selectedClients: [],
      customerToEdit: null,
      clientToEdit: null,
      alertText: "",
      alertType: "success",
      alertShow: false,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getCustomers();
    this.getClients();
  },
  watch: {
    selectedCustomerRow(newVal) {
      this.selectedClients = this.clients.filter((el) => {
        return el.customer_id == newVal.id;
      });
    },
  },
  methods: {
    showCustomerEditView(item) {
      this.customerToEdit = item;
      this.customerEditView = true;
    },
    showClientEditView(item) {
      this.clientToEdit = item;
      this.clientEditView = true;
    },
    showCustomerCreateView() {
      this.customerCreateView = true;
    },
    showClientCreateView() {
      this.clientCreateView = true;
    },
    closeCustomerEditView() {
      this.customerEditView = false;
    },
    closeClientEditView() {
      this.clientEditView = false;
    },
    closeCustomerCreateView() {
      this.customerCreateView = false;
    },
    closeClientCreateView() {
      this.clientCreateView = false;
    },
    toggleCustomerSelection(item) {
      this.selectedCustomerRow = item;
    },
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    toogleCustomerActive(customer) {
      this.customerTableLoading = true;
      this.updateCustomer(customer);
      this.customerTableLoading = false;
    },
    toogleClientActive(client) {
      this.clientTableLoading = true;
      this.updateClient(client);
      this.clientTableLoading = false;
    },
    async getCustomers() {
      this.customerTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/customers/getCustomer.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.customers = res.data;
            if (this.customers.lenght !== 0) {
              this.selectedCustomerRow = this.customers[0];
            } else {
              this.selectedCustomerRow = {};
            }
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania firm. Błąd: ${err}`, "error");
        });
      this.customerTableLoading = false;
    },
    async getClients() {
      this.clientTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/getClients.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.clients = res.data;
            this.clientTableLoading = false;
            this.selectedClients = this.clients.filter((el) => {
              return el.customer_id == this.selectedCustomerRow.id;
            });
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania klientów. Błąd: ${err}`, "error");
        });
      this.clientTableLoading = false;
    },
    async updateCustomer(customer) {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/customers/updateCustomer.php`,
        data: {
          token: localStorage.token,
          customer,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.showAlert("Zmiany zostały zapisane", "success");
            } else {
              this.showAlert(
                `Błąd podczas edytowania firmy. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.getCustomers();
            this.getClients();
          }
        })
        .catch((err) => {
          this.showAlert(
            `Błąd podczas edytowania firmy. Zmiany nie zostały zapisane. Błąd: ${err}`,
            "error"
          );
        });
    },
    async updateClient(client) {
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/updateClient.php`,
        data: {
          token: localStorage.token,
          client,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.showAlert("Zmiany zostały zapisane", "success");
            } else {
              this.showAlert(
                `Błąd podczas edytowania klienta. Zmiany nie zostały zapisane. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.getClients();
          }
        })
        .catch((err) => {
          this.showAlert(
            `Błąd podczas edytowania klienta. Zmiany nie zostały zapisane. Błąd: ${err}`,
            "error"
          );
        });
    },
  },
};
</script>