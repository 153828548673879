<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="dialog"
    @keydown.esc="closeConfirm"
  >
    <v-card>
      <v-toolbar color="secondary" dark>Potwierdzenie</v-toolbar>
      <v-card-text>
        <div class="text-h6 mt-8 text-center">
          Jesteś pewien że chcesz wysłać klientowi link do zresetownaia hasła?
        </div>
      </v-card-text>
      <v-card-actions class="justify-between">
        <v-container>
          <v-row>
            <v-col md="6"
              ><v-btn
                :loading="loading"
                block
                dark
                color="green"
                @click="resetPass"
                >Tak</v-btn
              ></v-col
            >
            <v-col md="6"
              ><v-btn
                :loading="loading"
                block
                dark
                color="red"
                @click="closeConfirm"
                >Nie</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    clientToResetPass: {
      type: Object,
      required: true,
    },
    clientConfirmView: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    clientConfirmView(newVal) {
      this.dialog = newVal;
    },
  },
  methods: {
    closeConfirm() {
      this.$emit("closeConfirm");
    },
    async resetPass() {
      this.loading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/resetPassClient.php`,
        data: {
          email: this.clientToResetPass.email,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.$emit("showAlert", "Link został wysłany", "success");
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas wysyłania linku do resetu hasła. Link nie został wysłany. Błąd: ${err}`,
            "error"
          );
        });
      this.loading = false;
      this.closeConfirm();
    },
  },
};
</script>