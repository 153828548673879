<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeCustomerEditView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeCustomerEditView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Edytowanie firmy: {{ customer.name }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              :loading="loading"
              color="green"
              @click="validateUpdateCustomer"
            >
              Aktualizuj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-16">
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                :loading="loading"
                v-model="name"
                label="Nazwa firmy"
                clearable
                ref="name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="nip"
                label="NIP"
                clearable
                ref="nip"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="phone"
                label="Nr telefonu"
                clearable
                ref="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-select
                v-model="activeSelect"
                :items="selectItems"
                :item-text="selectItems.text"
                :item-value="selectItems.isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                ref="active"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center"> </v-row>
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                :loading="loading"
                v-model="city"
                label="Miasto"
                clearable
                ref="city"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :loading="loading"
                v-model="address"
                label="Adres"
                clearable
                ref="address"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="building"
                label="Nr budynku"
                clearable
                ref="building"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="apartment"
                label="Nr mieszkania"
                clearable
                ref="apartment"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "CustomerEdit",
  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
      },
      dialog: false,
      name: "",
      phone: "",
      city: "",
      address: "",
      building: "",
      apartment: "",
      nip: "",
      loading: false,
      activeSelect: null,
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
    };
  },
  watch: {
    customerEditView(newVal) {
      this.dialog = newVal;
      this.name = this.customer.name;
      this.phone = this.customer.phone;
      this.city = this.customer.city;
      this.address = this.customer.address;
      this.building = this.customer.building_no;
      this.apartment = this.customer.apartment_no;
      this.nip = this.customer.nip;
      this.activeSelect = this.selectItems.filter((el) => {
        return el.isActive == this.customer.active;
      })[0];
    },
  },
  computed: {
    customer() {
      if (this.customerToEdit) {
        return this.customerToEdit;
      } else {
        return { id: 0, name: "test" };
      }
    },
    form() {
      return {
        name: this.name,
        city: this.city,
        address: this.address,
        building: this.building,
        nip: this.nip,
        active: this.activeSelect,
      };
    },
  },
  props: {
    customerToEdit: {
      required: true,
    },
    customerEditView: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeCustomerEditView() {
      this.$emit("closeCustomerEditView");
    },
    validateUpdateCustomer() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.updateCustomer();
      }
    },
    updateCustomer() {
      this.loading = true;
      let customer = {
        id: this.customer.id,
        name: this.name,
        phone: this.phone,
        city: this.city,
        address: this.address,
        building_no: this.building,
        apartment_no: this.apartment,
        nip: this.nip,
        active: this.activeSelect.isActive,
      };

      this.$emit("updateCustomer", customer);
      this.closeCustomerEditView();
      this.name = "";
      this.phone = "";
      this.city = "";
      this.address = "";
      this.building = "";
      this.apartment = "";
      this.nip = "";
      this.loading = false;
    },
  },
};
</script>