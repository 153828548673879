<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeClientCreateView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeClientCreateView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodawanie nowego klienta</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark color="green" @click="validateCreateClient">
              Dodaj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-8">
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                ref="name"
                v-model="name"
                label="Nazwa"
                clearable
                :loading="loading"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                ref="password"
                v-model="password"
                label="Hasło"
                :loading="loading"
                :type="showpassword ? 'text' : 'password'"
                counter
                :rules="[rules.required, rules.counter, rules.min]"
                :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                append-outer-icon="mdi-lock-plus"
                @click:append="showpassword = !showpassword"
                @click:append-outer="passwordGenerator"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-select
                ref="active"
                v-model="activeSelect"
                :items="selectItems"
                item-text="text"
                item-value="isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                ref="phone"
                v-model="phone"
                label="Nr telefonu"
                clearable
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                ref="email"
                v-model="email"
                label="Email"
                clearable
                :loading="loading"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                ref="customerId"
                v-model="selectedCustomer"
                :items="customers"
                item-text="name"
                item-value="id"
                clearable
                label="Firma"
                :loading="loading"
                :rules="[rules.required]"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ClientCreate",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
        counter: (value) => value.length <= 20 || "Max 20 znaków",
        min: (v) => v.length >= 6 || "Min 6 znaków",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
      dialog: false,
      loading: false,
      phone: "",
      password: "",
      showpassword: false,
      name: "",
      email: "",
      selectedCustomer: null,
      activeSelect: {
        isActive: 1,
        text: "Tak",
      },
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
    };
  },
  computed: {
    form() {
      return {
        password: this.password,
        name: this.name,
        email: this.email,
        active: this.activeSelect,
        customerId: this.selectedCustomer,
      };
    },
  },
  watch: {
    clientCreateView(newVal) {
      this.dialog = newVal;
    },
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
    clientCreateView: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeClientCreateView() {
      this.$emit("closeClientCreateView");
    },
    validateCreateClient() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.createClient();
      }
    },
    passwordGenerator() {
      var length = 10,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      this.password = retVal;
    },
    async createClient() {
      let client = {
        phone: this.phone,
        password: this.password,
        name: this.name,
        email: this.email,
        active: this.activeSelect.isActive,
        customer_id: this.selectedCustomer,
      };
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/clients/createClient.php`,
        data: {
          token: localStorage.token,
          client,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.$emit("showAlert", "Klient został dodany.", "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas dodawania klienta. Klient nie został dodany. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.$emit("getClients");
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas dodawania klienta. Klient nie został dodany. Błąd: ${err}`,
            "error"
          );
        });
      this.closeClientCreateView();
      this.loading = false;
    },
  },
};
</script>

<style>
.client-create-main {
  height: 70vh;
}
</style>