<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeClientEditView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeClientEditView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >Edytowanie pracownika: {{ client.name }} ({{
              client.email
            }})</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              :loading="loading"
              color="green"
              @click="validateUpdateClient"
            >
              Aktualizuj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-16">
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                ref="name"
                v-model="name"
                label="Nazwa"
                clearable
                :rules="[rules.required]"
                :loading="loading"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <v-autocomplete
                ref="customerId"
                v-model="selectedCustomer"
                :items="customers"
                item-text="name"
                item-value="id"
                clearable
                label="Firma"
                :rules="[rules.required]"
                :loading="loading"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="1">
              <v-select
                ref="active"
                v-model="activeSelect"
                :items="selectItems"
                item-text="text"
                item-value="isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                ref="email"
                v-model="email"
                label="Email"
                clearable
                :rules="[rules.required, rules.email]"
                :loading="loading"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                ref="phone"
                v-model="phone"
                label="Nr telefonu"
                clearable
                :loading="loading"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "ClientEdit",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
        counter: (value) => value.length <= 20 || "Max 20 znaków",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Błędny email.";
        },
      },
      dialog: false,
      loading: false,
      phone: "",
      name: "",
      email: "",
      selectedCustomer: {},
      activeSelect: null,
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
    };
  },
  watch: {
    clientEditView(newVal) {
      this.dialog = newVal;
      this.phone = this.client.phone;
      this.name = this.client.name;
      this.email = this.client.email;
      this.activeSelect = this.selectItems.filter((el) => {
        return el.isActive == this.client.active;
      })[0];
      this.selectedCustomer = this.customers.filter((el) => {
        return el.id == this.client.customer_id;
      })[0].id;
    },
  },
  computed: {
    client() {
      if (this.clientToEdit) {
        return this.clientToEdit;
      } else {
        return { id: 0, phone: "test", name: "test", selectedCustomer: 0 };
      }
    },
    form() {
      return {
        name: this.name,
        email: this.email,
        active: this.activeSelect,
        customerId: this.selectedCustomer,
      };
    },
  },
  props: {
    clientToEdit: {
      required: true,
    },
    clientEditView: {
      type: Boolean,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    closeClientEditView() {
      this.$emit("closeClientEditView");
    },
    validateUpdateClient() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.updateClient();
      }
    },
    updateClient() {
      this.loading = true;
      let client = {
        id: this.clientToEdit.id,
        phone: this.phone,
        name: this.name,
        email: this.email,
        active: this.activeSelect.isActive,
        customer_id: this.selectedCustomer,
      };
      this.$emit("updateClient", client);
      this.closeClientEditView();
      this.loading = false;
    },
  },
};
</script>