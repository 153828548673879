var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Szukaj","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.clients,"search":_vm.search,"loading":_vm.clientTableLoading,"loading-text":"Wczytywanie... Proszę czekać","footer-props":{
      'items-per-page-text': 'Klientów na stronę',
      'items-per-page-options': [10, 25, 50, 100],
    },"items-per-page":10,"sort-by":['active', 'name'],"sort-desc":true,"multi-sort":"","header-props":_vm.headerProps},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(_vm._s(item.phone))]),_c('td',{class:item.active ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(item.active ? "Tak" : "Nie")+" ")]),_c('td',{attrs:{"width":"10px"}},[_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-menu")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"block":"","color":"warning"},on:{"click":function($event){$event.preventDefault();return _vm.showClientEditView(item)}}},[_vm._v("Edytuj")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"block":"","color":"red","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.toogleClientActive(item)}}},[_vm._v(_vm._s(item.active == 1 ? "Archiwizuj" : "Przywróć"))])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"block":"","color":"red","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.showConfirm(item)}}},[_vm._v("Reset hasła")])],1)],1)],1)],1)],1)])]}},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+"-"+_vm._s(items.pageStop)+" z "+_vm._s(items.itemsLength)+" ")]}}])}),_c('ClientConfirmPassReset',{attrs:{"clientConfirmView":_vm.clientConfirmView,"clientToResetPass":_vm.clientToResetPass,"apiLink":_vm.apiLink},on:{"closeConfirm":_vm.closeConfirm,"showAlert":_vm.showAlert}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }