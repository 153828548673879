<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="clients"
      :search="search"
      :loading="clientTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :footer-props="{
        'items-per-page-text': 'Klientów na stronę',
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :items-per-page="10"
      class="elevation-1"
      :sort-by="['active', 'name']"
      :sort-desc="true"
      multi-sort
      :header-props="headerProps"
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.phone }}</td>
          <td :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "Tak" : "Nie" }}
          </td>
          <td width="10px">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="warning"
                      @click.prevent="showClientEditView(item)"
                      >Edytuj</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="toogleClientActive(item)"
                      >{{ item.active == 1 ? "Archiwizuj" : "Przywróć" }}</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="red"
                      dark
                      @click.prevent="showConfirm(item)"
                      >Reset hasła</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
    <ClientConfirmPassReset
      :clientConfirmView="clientConfirmView"
      :clientToResetPass="clientToResetPass"
      :apiLink="apiLink"
      @closeConfirm="closeConfirm"
      @showAlert="showAlert"
    />
  </v-card>
</template>

<script>
import ClientConfirmPassReset from "@/components/CMS/Customers/ClientConfirmPassReset.vue";

export default {
  data() {
    return {
      clientConfirmView: false,
      clientToResetPass: {},
      search: "",
      headers: [
        { text: "Nazwa", value: "name" },
        { text: "Email", value: "email" },
        { text: "Nr telefonu", value: "phone" },
        { text: "Aktywność", value: "active" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    clients: {
      type: Array,
      required: true,
    },
    clientTableLoading: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ClientConfirmPassReset,
  },
  methods: {
    showClientEditView(item) {
      this.$emit("showClientEditView", item);
    },
    toogleClientActive(client) {
      client.active = !client.active;
      this.$emit("toogleClientActive", client);
    },
    showConfirm(client) {
      this.clientToResetPass = client;
      this.clientConfirmView = true;
    },
    closeConfirm() {
      this.clientConfirmView = false;
    },
    showAlert(text, type) {
      this.$emit("showAlert", text, type);
    },
  },
};
</script>