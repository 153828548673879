<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Szukaj"
        single-line
        hide-details
        clearable
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="customers"
      :search="search"
      :loading="customerTableLoading"
      loading-text="Wczytywanie... Proszę czekać"
      :footer-props="{
        'items-per-page-text': 'Firmy na stronę',
        'items-per-page-options': [10, 25, 50, 100],
      }"
      :items-per-page="10"
      class="elevation-1"
      :sort-by="['active', 'name']"
      :sort-desc="true"
      :header-props="headerProps"
      multi-sort
    >
      <template v-slot:item="{ item }">
        <tr
          :class="
            selectedCustomerRow.id == item.id
              ? 'blue lighten-4 black--text'
              : ''
          "
          @click="toggleCustomerSelection(item)"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.phone }}</td>
          <td>{{ item.city }}</td>
          <td>{{ item.address }}</td>
          <td>{{ item.building_no }}</td>
          <td>{{ item.apartment_no }}</td>
          <td>{{ item.nip }}</td>
          <td :class="item.active ? 'green--text' : 'red--text'">
            {{ item.active ? "Tak" : "Nie" }}
          </td>
          <td width="10px">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" small v-bind="attrs" v-on="on">
                  <v-icon>mdi-menu</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      color="warning"
                      @click.prevent="showCustomerEditView(item)"
                      >Edytuj</v-btn
                    ></v-list-item-title
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-title
                    ><v-btn
                      block
                      dark
                      color="red"
                      @click.prevent="toogleCustomerActive(item)"
                    >
                      {{ item.active == 1 ? "Archiwizuj" : "Przywróć" }}</v-btn
                    >
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Nazwa", value: "name" },
        { text: "Nr telefonu", value: "phone" },
        { text: "Miasto", value: "city" },
        { text: "Adres", value: "address" },
        { text: "Nr budynku", value: "building_no" },
        { text: "Nr mieszkania", value: "apartment_no" },
        { text: "NIP", value: "nip" },
        { text: "Aktywny", value: "active" },
        { text: "Akcje", sortable: false },
      ],
      headerProps: {
        sortByText: "Sortowanie",
      },
    };
  },
  props: {
    customers: {
      type: Array,
      required: true,
    },
    selectedCustomerRow: {
      type: Object,
      required: true,
    },
    customerTableLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    showCustomerEditView(item) {
      this.$emit("showCustomerEditView", item);
    },
    toggleCustomerSelection(item) {
      if (this.selectedCustomerRow != item) {
        this.$emit("toggleCustomerSelection", item);
      }
    },
    toogleCustomerActive(customer) {
      customer.active = !customer.active;
      this.$emit("toogleCustomerActive", customer);
    },
  },
};
</script>
