<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      @keydown.esc="closeCustomerCreateView"
    >
      <v-card>
        <v-toolbar dark color="secondary">
          <v-btn icon dark @click="closeCustomerCreateView">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Dodawanie nowej firmy</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark color="green" @click="validateCreateCustomer">
              Dodaj
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-container class="mt-16">
          <v-row justify="center">
            <v-col cols="12" md="3">
              <v-text-field
                :loading="loading"
                v-model="name"
                label="Nazwa firmy"
                clearable
                ref="name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="nip"
                label="NIP"
                clearable
                ref="nip"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="phone"
                label="Nr telefonu"
                clearable
                ref="phone"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-select
                v-model="activeSelect"
                :items="selectItems"
                :item-text="selectItems.text"
                :item-value="selectItems.isActive"
                return-object
                label="Aktywność"
                :loading="loading"
                ref="active"
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center"> </v-row>
          <v-row justify="center">
            <v-col cols="12" md="2">
              <v-text-field
                :loading="loading"
                v-model="city"
                label="Miasto"
                clearable
                ref="city"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                :loading="loading"
                v-model="address"
                label="Adres"
                clearable
                ref="address"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="building"
                label="Nr budynku"
                clearable
                ref="building"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1">
              <v-text-field
                :loading="loading"
                v-model="apartment"
                label="Nr mieszkania"
                clearable
                ref="apartment"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "EmployeeCreate",

  data() {
    return {
      rules: {
        required: (value) => !!value || "Wymagane.",
      },
      dialog: false,
      name: "",
      phone: "",
      city: "",
      address: "",
      building: "",
      apartment: "",
      nip: "",
      loading: false,
      activeSelect: {
        isActive: 1,
        text: "Tak",
      },
      selectItems: [
        {
          isActive: 1,
          text: "Tak",
        },
        {
          isActive: 0,
          text: "Nie",
        },
      ],
    };
  },
  computed: {
    form() {
      return {
        name: this.name,
        city: this.city,
        address: this.address,
        nip: this.nip,
        active: this.activeSelect,
      };
    },
  },
  watch: {
    customerCreateView(newVal) {
      this.dialog = newVal;
    },
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
    customerCreateView: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    closeCustomerCreateView() {
      this.$emit("closeCustomerCreateView");
    },
    validateCreateCustomer() {
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;

        this.$refs[f].validate(true);
      });

      if (!this.formHasErrors) {
        this.createCustomer();
      }
    },
    async createCustomer() {
      let customer = {
        name: this.name,
        phone: this.phone,
        city: this.city,
        address: this.address,
        building_no: this.building,
        apartment_no: this.apartment,
        nip: this.nip,
        active: this.activeSelect.isActive,
      };
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/customers/createCustomer.php`,
        data: {
          token: localStorage.token,
          customer,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            if (res.data == 1) {
              this.$emit("showAlert", "Firma została dodana.", "success");
            } else {
              this.$emit(
                "showAlert",
                `Błąd podczas dodawania firmy. Firma nie została dodana. Bład w API: ${res.data}`,
                "error"
              );
            }
            this.$emit("getCustomers");
          } else {
            this.$emit(
              "showAlert",
              `Błąd podczas dodawania firmy. Firma nie została dodana. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.$emit(
            "showAlert",
            `Błąd podczas dodawania firmy. Firma nie została dodana. Błąd: ${err}`,
            "error"
          );
        });
      this.closeCustomerCreateView();
      this.loading = false;
    },
  },
};
</script>